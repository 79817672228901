<template>
  <div class="single-post-entry" ref="post">
    <div class="wrapper">
      <div class="image">
        <img :src="featuredImage" alt="" data-key="featured-image" />
      </div>
      <div class="data">
        <div class="user">
          <div class="image">
            <img :src="userPhoto" alt="" v-if="userPhoto" data-key="user-image" />
            <svg-icon class="icon" name="user-solid" v-else></svg-icon>
          </div>
          <div :class="['post-icon', postContent.platform]">
            <svg-icon class="icon" :name="postContent.platform" v-if="postContent.platform"></svg-icon>
          </div>
        </div>
        <div class="info">
          <div class="title">
            {{ postContent.title }}
          </div>
          <div class="date">{{ formattedDate }} - {{ date }}</div>
          <div class="post">
            <span class="title">
              {{ content }} <span class="username"> {{ userFullName }}</span>
            </span>
            <span class="link" v-if="postLink()">
              <a :href="postLink()" class="url" target="_blank">
                {{ postLink(true) }} <span class="more"> - read more</span>
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="analytics">
        <div class="item" v-for="(value, key) in analytics" :key="key">
          <div class="title">
            {{ key }}
          </div>
          <div class="value">
            {{ value }}
          </div>
        </div>
      </div>
      <slot name="inner-wrapper"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from 'moment';
import { get, isNull } from 'lodash';
import imagesLoaded from 'imagesloaded';
import { stripTags } from '~/utils/helpers';
import truncate from 'smart-truncate';

export default {
  name: 'single-post-preview',
  props: {
    postContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      date: null,
      imgload: null,
      processedImages: {},
    };
  },
  created() {
    this.interval = setInterval(() => (this.date = moment.unix(this.postContent.createdAt).fromNow()), 1000);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    if (this.imgload) this.imgload.off('progress', this.handleImageLoad.bind(this));
  },
  async mounted() {
    await this.$nextTick();
    const { post } = this.$refs;

    if (!post) return;
    this.imgload = imagesLoaded(post);
    this.imgload.on('progress', this.handleImageLoad.bind(this));
  },
  computed: {
    userFullName() {
      const {
        user: { first_name, last_name },
      } = this.postContent;
      return `${first_name} ${last_name}`;
    },
    featuredImage() {
      const processedUrl = get(this.processedImages, 'featured-image', null);
      const defaultImage = require('@/assets/images/guy.png');

      if (isNull(processedUrl)) return get(this.postContent, 'image', defaultImage);
      if (processedUrl === false) return defaultImage;
      return processedUrl;
    },
    userPhoto() {
      const processedUrl = get(this.processedImages, 'user-image', null);

      if (isNull(processedUrl)) return get(this.post, 'user.avatar');
      if (processedUrl === false) return false;
    },
    content() {
      return truncate(stripTags(this.postContent.content), 150);
    },
    postLink() {
      return (truncateText = false) => {
        const link = get(this.postContent, 'link');
        if (!link) return false;
        if (!truncateText) return link;

        return truncate(link, 35, { position: 25 });
      };
    },
    analytics() {
      return {
        engagements: get(this.postContent, 'reports.engagememnts', 0),
        likes: get(this.postContent, 'reports.likes', 0),
        comments: get(this.postContent, 'reports.comments', 0),
        shares: get(this.postContent, 'reports.shares', 0),
      };
    },
    formattedDate() {
      return moment.unix(this.postContent.createdAt).format('dddd MMMM Do YYYY, h:mm:ss a');
    },
  },
  methods: {
    handleImageLoad(instance, { img, isLoaded }) {
      const key = img.dataset.key;
      this.$set(this.processedImages, key, isLoaded ? img.src : false);
    },
  },
};
</script>

<style lang="scss" scoped>
.single-post-entry {
  display: flex;
  width: calc(100% - 20px);
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: stretch;
  justify-content: flex-start;
  margin: 20px 0 0 0;

  > .wrapper {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #ffffff;
    border: none;
    margin: 0;

    > .image {
      display: inline-flex;
      width: 130px;
      height: 150px;
      border-radius: 10px;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .data {
      display: inline-flex;
      padding: 10px 0 0 0;
      margin: 0 0 0 14px;
      flex-wrap: nowrap;
      align-items: flex-start;

      > .user {
        display: inline-flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #c4c4c4;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        flex-grow: 1;

        > .image {
          display: inline-flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          > .icon {
            width: 30px;
            height: 30px;
            fill: #e9e9e9;
          }
        }

        > .post-icon {
          display: inline-flex;
          position: absolute;
          bottom: -12px;
          width: 24px;
          height: 24px;
          color: #ffffff;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

          > .icon {
            width: 14px;
            height: 14px;
            margin: 4px 0 0 0;
          }

          &.facebook {
            background-color: #3b5998;

            > .icon {
              width: 13px;
              height: 13px;
              margin: 3px 0 0 0;
            }
          }

          &.twitter {
            background-color: #3d82b6;
          }

          &.reddit {
            background-color: #bd3737;
          }

          &.linkedin {
            background-color: #46e1c4;
          }

          &.wordpress {
            background-color: #ae7c31;
          }

          &.medium {
            background-color: #9e3192;
          }

          > .icon {
            display: inline-flex;
            transform: scale(0.8);
          }
        }
      }

      > .info {
        display: inline-flex;
        margin: 5px 0 0 14px;
        width: 230px;
        flex-wrap: wrap;
        flex-shrink: 0;
        flex-grow: 0;

        > * {
          display: flex;
          width: 100%;
          justify-content: flex-start;
        }

        > .title {
          font-size: 16px;
          text-transform: capitalize;
          color: #000000;
          font-weight: 700;
          font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
            'Helvetica Neue', sans-serif;
          font-style: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .date {
          margin: 8px 0 0 0;
          font-size: 12px;
          line-height: 12px;
          text-transform: capitalize;
          color: #868686;
          padding: 0;
        }

        > .post {
          font-size: 12px;
          font-weight: 700;
          color: #404040;
          text-transform: capitalize;
          flex-wrap: wrap;
          margin: 12px 0 0 0;

          > .title {
            width: 100%;
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            flex-wrap: wrap;
            word-wrap: break-word;

            > .username {
              display: inline-flex;
              margin: 0;

              &:visited,
              & {
                color: #3f51b1;
                font-weight: 400;
                align-items: center;

                &::before {
                  content: '-';
                  display: inline-flex;
                  margin: 0 3px 0 3px;
                }
              }
            }
          }

          > .link {
            width: 100%;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-flex;

            > .url {
              width: auto;
              max-width: 100%;
              white-space: nowrap;
              flex-shrink: 1;
              flex-grow: 0;
              text-transform: lowercase;
              overflow: hidden;

              > .more {
                display: inline-flex;
                margin: 0 3px 0 3px;
                text-transform: capitalize;
                flex-shrink: 0;
                flex-grow: 1;

                &:visited,
                & {
                  color: #3f51b1;
                  font-weight: 400;
                  align-items: center;
                }
              }

              &:visited,
              & {
                color: #3f51b1;
                font-weight: 400;
                align-items: center;
              }
            }
          }
        }
      }
    }

    > .analytics {
      display: inline-flex;
      flex-grow: 1;
      flex-shrink: 0;
      flex-wrap: nowrap;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      margin: 0 0 0 15px;

      > .item {
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
        height: 100%;
        font-size: 13px;
        color: #000000;
        margin: 0;
        padding: 0;
        border: none;
        flex-grow: 1;

        > .title {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          text-transform: capitalize;
          flex-grow: 0;
          flex-shrink: 0;
          background-color: #f6f6f6;
        }

        > .value {
          display: flex;
          flex-grow: 1;
          flex-shrink: 1;
          justify-content: center;
          align-items: center;
          background-color: #f0f0f0;
        }
      }
    }
  }
}
</style>
